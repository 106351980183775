import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'
import { PostHeader } from '../components/postHeader'

// http://www.dktech.cz/?paged=2
// http://www.dktech.cz/?p=247

const PostUkonceniZkusebniDoby: FC = () => {
  return (
    <PageTemplate
      prevLink="/"
      nextLink="/post_velikonocni_event_amulety_moci"
    >
      <PostHeader
        title="Ukončení zkušební doby"
        link="/"
        author="Algy"
        date="02.05.2014"
        time="11:30"
        category="Oznámení"
      />
      <p>Zdravím,</p>
      <p>dne 29. dubna 2014 byla ukončena zkušební doba GM Bachar a ten je nyní již plnohodnotné GM. Svojí aktivitou i přístupem se osvědčil. Přejeme mu spousty pevných nervů a dlouhotrvající odhodlání.</p>
      <p style={{ textAlign: 'right' }}>Za vedení server DKTech.cz GM Algy</p>


      <div className="cleared">&nbsp;</div>
    </PageTemplate>
  )
}

export default PostUkonceniZkusebniDoby

export { Head } from '../components/defaultHtmlHead'